<template>
  <div class="wrapper">
    <div class="container">
      <Logo />
      <h1>Калькулятор</h1>
      <div class="content">
        <form class="form" action="">

          <div class="form__highlight">
            <div class="form__row">
              <h3>{{ startData.name }}</h3>
              <div class="form__item">
                <select
                  name="start"
                  id="start"
                  v-model="start"
                  @change="resetComplectfurnitura"
                >
                  <option disabled value="0">Выберите вариант</option>
                  <option
                    v-for="(item, index) in startData.variants"
                    :key="index"
                    :value="item"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="form__item">
                <select
                  name="startVar"
                  id="startVar"
                  v-model="startType"
                  class="child"
                  v-if="start"
                  @change="resetMetall"
                  required
                >
                  <option disabled value="0">Выберите вариант</option>
                  <option
                    v-for="(item, index) in start.variant"
                    :key="index"
                    :value="item"
                    :class="{ disabled: item.price == 0 }"
                    :title="item.description"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="form__item form__description" v-if="startType.description">
                <p>Стоимость конструкции: <strong>{{ parseInt(startType.price) + parseInt(startType.price) * (parseInt(startType.extra) / 100) }} ₽</strong></p>
                <p>{{ startType.description }}</p>
              </div>
            </div>

            <div class="form__row" v-if="start.id == 3 && startType">
              <h4>Толщина металла</h4>
              <div class="form__item">
                <select name="metal" id="metal" v-model="metal">
                  <option
                    v-for="(item, index) in startType.metal"
                    :key="index"
                    :value="item.extra"
                    :selected="index === 0"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="form__item price">{{ metal }} ₽</div>
            </div>

            <div class="form__row align_bottom" v-if="start.id == 2 && startType">
              <div class="form__item">
                <label for="metal">{{ metalData.name }}</label>
                <select name="metal" id="metal" v-model="metal">
                  <option disabled value="">Выберите вариант</option>
                  <option
                    v-for="(item, index) in metalData.variant"
                    :key="index"
                    :value="item.price"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="form__item price">{{ metal }} ₽</div>
            </div>

            <div class="form__row" v-if="start.id == 3 && startType">
              <h4>Размер</h4>
              <div class="form__item">
                <label for="height">Высота (мм)</label>
                <input id="height" type="number" :min="nestHeightMin" :max="nestHeightMax" v-model="nestHeightCurrent" @change="() => {if(nestHeightCurrent < nestHeightMin) {this.nestHeightCurrent = nestHeightMin} else if(nestHeightCurrent > nestHeightMax) {this.nestHeightCurrent = nestHeightMax}}" />
                <div class="form__item form__description">от {{ nestHeightMin }} до {{ nestHeightMax }}</div>
              </div>
              <div class="form__item" :class="nestHeightCurrent ? 'is_active' : 'not_active'">
                <label for="height">Ширина (мм)</label>
                <input id="height" type="number" :min="nestWidthMin" :max="nestWidthMax" v-model="nestWidthCurrent" @change="() => {if(nestWidthCurrent < nestWidthMin) {this.nestWidthCurrent = nestWidthMin} else if(nestWidthCurrent > nestWidthMax) {this.nestWidthCurrent = nestWidthMax}}" />
                <div class="form__item form__description">от {{ nestWidthMin }} до {{ nestWidthMax }}</div>
              </div>
            </div>

            <div class="form__row" v-if="start.id == 4 && startType">
              <h4>Размер</h4>
              <div class="form__item">
                <label for="height">Высота (мм)</label>
                <input id="height" type="number" :min="dvHeightMin" :max="dvHeightMax" v-model="dvHeightCurrent" @change="() => {if(dvHeightCurrent < dvHeightMin) {this.dvHeightCurrent = dvHeightMin} else if(dvHeightCurrent > dvHeightMax) {this.dvHeightCurrent = dvHeightMax}}" />
                <div class="form__item form__description">от {{ dvHeightMin }} до {{ dvHeightMax }}</div>
              </div>
              <div class="form__item" :class="dvHeightCurrent ? 'is_active' : 'not_active'">
                <label for="height">Ширина (мм)</label>
                <input id="height" type="number" :min="dvWidthMin" :max="dvHeightMax" v-model="dvWidthCurrent" @change="() => {if(dvWidthCurrent < dvWidthMin) {this.dvWidthCurrent = dvWidthMin} else if(dvWidthCurrent > dvHeightMax) {this.dvWidthCurrent = dvHeightMax}}" />
                <div class="form__item form__description">от {{ dvWidthMin }} до {{ dvWidthMax }}</div>
              </div>
            </div>

            <div class="form__item form__description form__price" v-if="parseInt(startType.price)">
              Итого конструкции: <strong>{{ (parseInt(startType.price) + parseInt(startType.price) * (parseInt(startType.extra) / 100) + parseInt(metal)) * parseFloat((start.id == 3 && startType) ? nestValue : 1) * parseFloat(start.id == 4 && startType ? (dvWidthCurrent > dvWidthMid ? dvValueMax : dvValue) : 1) }} ₽</strong><span class="price__description" v-if="start.id == 3 && startType || start.id == 4 && startType">
                (Коофициент: {{ (start.id == 3 && startType) ? nestValue : '' }}{{ start.id == 4 && startType ? (dvWidthCurrent > dvWidthMid ? dvValueMax : dvValue) : '' }})</span>
            </div>

          </div>

          <!--Фрамуга-->
          <div class="form__highlight" v-if="start.id == 3 && startType || start.id == 4 && startType">
            <div class="form__row">
              <div class="form__item form__checkboxes">
                <div class="checks">
                  <label for="framuga">
                    <input
                      type="checkbox"
                      v-model="framuga"
                      id="framuga"
                      name="framuga"
                      @change="resetFramugaType"
                    />
                    <span>Фрамуга</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form__row" v-if="framuga">
              <label>Укажите данные:</label>
              <div class="form__item form__checkboxes">
                <div class="checks">
                  <label for="framuga_termo">
                    <input
                      type="checkbox"
                      v-model="framuga_termo"
                      id="framuga_termo"
                      name="framuga_termo"
                    />
                    <span>Термо</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form__row" v-if="framuga">
              <div class="form__item">
                <label for="height">Высота (мм)</label>
                <input id="height" type="number" :min="framugaHeightMin" :max="framugaHeightMax" v-model="framugaHeightCurrent" @change="() => {
                    felenka_type = 0;
                    framuga_stekopaket = 0;
                    if(framugaHeightCurrent < framugaHeightMin) {this.framugaHeightCurrent = framugaHeightMin}
                    else if(framugaHeightCurrent > framugaHeightMax) {this.framugaHeightCurrent = framugaHeightMax}
                    else if(framugaHeightCurrent <= 99) {this.framuga_price = 2000}
                    else if(framugaHeightCurrent > 99 && framugaHeightCurrent <= 199) {this.framuga_price = 2400}
                    else if(framugaHeightCurrent > 199 && framugaHeightCurrent <= 499) {this.framuga_price = 3500}
                    else if(framugaHeightCurrent > 499 && framugaHeightCurrent <= 749) {this.framuga_price = 4600}
                    else if(framugaHeightCurrent > 749 && framugaHeightCurrent <= 1000) {this.framuga_price = 5500}
                  }
                " />
                <div class="form__item form__description">от {{ framugaHeightMin }} до {{ framugaHeightMax }}</div>
              </div>
            </div>

            <div class="form__row" v-if="framuga && framugaHeightCurrent >= 100">
              <div class="form__item">
                <label for="height">Вариант</label>
                <select name="felenka_type" id="felenka_type" v-model="felenka_type" required @change="() => {
                    if(felenka_type == 1 && framugaHeightCurrent >= 300) {this.felenka_price = 5000}
                    else if(felenka_type == 2 && framugaHeightCurrent >= 500) {this.felenka_price = 7000}
                    else if(felenka_type == 3) {this.felenka_price = 9000}
                  }"
                >
                  <option disabled value="0">Выберите вариант</option>
                  <option value="1" v-if="framugaHeightCurrent >= 300">Феленка “СБ Мадрид”</option>
                  <option value="2" v-if="framugaHeightCurrent >= 500">Феленка “Ватикан”</option>
                  <option value="3">Панели</option>
                </select>
              </div>
            </div>

            <div class="form__row" v-if="framuga && framugaHeightCurrent >= 100 && felenka_type == 3">
              <div class="form__item">
                <label for="height">Выберите панель</label>
                <select name="panel_type" id="panel_type" v-model="panel_type" required @change="() => {
                    if(panel_type == 1) {this.panel_price = 100}
                    else if(panel_type == 2) {this.panel_price = 200}
                  }"
                >
                  <option disabled value="0">Выберите вариант</option>
                  <option value="1">Накладная</option>
                  <option value="2">Под рамкой</option>
                </select>
              </div>
            </div>

            <div class="form__row" v-if="framuga && framugaHeightCurrent >= 300">
              <div class="form__item form__checkboxes">
                <div class="checks">
                  <label for="framuga_stekopaket">
                    <input
                      type="checkbox"
                      v-model="framuga_stekopaket"
                      id="framuga_stekopaket"
                      name="framuga_stekopaket"
                      @change="resetSteklopaket"
                    />
                    <span>Стеклопакет</span>
                  </label>
                </div>
              </div>
            </div>
            
            <div class="form__highlight" v-if="framuga && framuga_stekopaket">
              <div class="form__row">
                <div class="form__item">
                  <select name="steklopaket_type" id="steklopaket_type" v-model="steklopaket_type" required @change="() => {
                      decor_type = 0;
                      decor_led = 0;
                      decor_side_type = 0;
                      if(steklopaket_type == 1) {this.steklopaket_price = 5000}
                      else if(steklopaket_type == 2) {this.steklopaket_price = 7000}
                    }"
                  >
                    <option disabled value="0">Выберите вариант</option>
                    <option value="1">Двухкамерный</option>
                    <option value="2">Трехкамерный</option>
                  </select>
                </div>
              </div>

              <div class="form__row" v-if="steklopaket_type">
                <div class="form__item">
                  <label for="height">Декор</label>
                  <select name="steklopaket_type" id="steklopaket_type" v-model="decor_type" required @change="() => {
                      decor_side_type = 0;
                      if(decor_type == 1) {this.decor_price = 1000}
                      else if(decor_type == 2) {this.decor_price = 2000}
                    }"
                  >
                    <option disabled value="0">Выберите вариант</option>
                    <option value="1">Ковка</option>
                    <option value="2">Лазерная резка</option>
                  </select>
                </div>
              </div>

              <div class="form__row" v-if="decor_type">
                <div class="form__item">
                  <label for="height">Размещение</label>
                  <select name="steklopaket_type" id="steklopaket_type" v-model="decor_side_type" required @change="() => {
                      if(decor_side_type == 1) {this.decor_side_price = 1000}
                      else if(decor_side_type == 2) {this.decor_side_price = 2000}
                    }"
                  >
                    <option disabled value="0">Выберите вариант</option>
                    <option value="1">Снаружи</option>
                    <option value="2">Внутри</option>
                  </select>
                </div>
              </div>

              <div class="form__row" v-if="steklopaket_type">
                <div class="form__item form__checkboxes">
                  <div class="checks">
                    <label for="decor_led">
                      <input
                        type="checkbox"
                        v-model="decor_led"
                        id="decor_led"
                        name="decor_led"
                      />
                      <span>LED подсветка</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="form__item form__description form__price" v-if="framuga">
              Стоимость фрамуги: <strong> {{ parseInt(framuga ? framuga_price : 0) + parseInt(framuga_termo ? 5000 : 0) + parseInt(framuga_madrid ? 3500 : 0) + parseInt(steklopaket_price) + parseInt(panel_price) }} ₽</strong>
            </div>
          </div>

          <!--/Фрамуга-->

          <!--Полка-->
          <div class="form__highlight" v-if="start.id == 3 && startType || start.id == 4 && startType">
            <div class="form__row">
              <div class="form__item form__checkboxes">
                <div class="checks">
                  <label for="polka">
                    <input
                      type="checkbox"
                      v-model="polka"
                      id="polka"
                      name="polka"
                      @change="resetPolkaType"
                    />
                    <span>Полка</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form__row" v-if="polka">
              <label>Укажите данные:</label>
              <div class="form__item form__checkboxes">
                <div class="checks">
                  <label for="polka_termo">
                    <input
                      type="checkbox"
                      v-model="polka_termo"
                      id="polka_termo"
                      name="polka_termo"
                    />
                    <span>Термо</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form__row" v-if="polka">
              <div class="form__item">
                <label for="height">Высота (мм)</label>
                <input id="height" type="number" :min="polkaHeightMin" :max="polkaHeightMax" v-model="polkaHeightCurrent" @change="() => {
                    felenka_polka_type = 0;
                    framuga_polka_stekopaket = 0;
                    if(polkaHeightCurrent < polkaHeightMin) {this.polkaHeightCurrent = polkaHeightMin}
                    else if(polkaHeightCurrent > polkaHeightMax) {this.polkaHeightCurrent = polkaHeightMax}
                    else if(polkaHeightCurrent <= 99) {this.polka_price = 2600}
                    else if(polkaHeightCurrent > 99 && polkaHeightCurrent <= 199) {this.polka_price = 3700}
                    else if(polkaHeightCurrent > 199 && polkaHeightCurrent <= 399) {this.polka_price = 5200}
                    else if(polkaHeightCurrent > 399 && polkaHeightCurrent <= 600) {this.polka_price = 6900}
                  }
                " />
                <div class="form__item form__description">от {{ polkaHeightMin }} до {{ polkaHeightMax }}</div>
              </div>
            </div>

            <div class="form__row" v-if="polka && polkaHeightCurrent >= 100">
              <div class="form__item">
                <label for="height">Вариант</label>
                <select name="felenka_polka_type" id="felenka_polka_type" v-model="felenka_polka_type" required @change="() => {
                    if(felenka_polka_type == 1 && polkaHeightCurrent >= 300) {this.felenka_polka_price = 5000}
                    else if(felenka_polka_type == 2 && polkaHeightCurrent >= 500) {this.felenka_polka_price = 7000}
                    else if(felenka_polka_type == 3) {this.felenka_polka_price = 9000}
                  }"
                >
                  <option disabled value="0">Выберите вариант</option>
                  <option value="1" v-if="polkaHeightCurrent >= 300">Феленка “СБ Мадрид”</option>
                  <option value="2" v-if="polkaHeightCurrent >= 500">Феленка “Ватикан”</option>
                  <option value="3">Панели</option>
                </select>
              </div>
            </div>

            <div class="form__row" v-if="polka && polkaHeightCurrent >= 100 && felenka_polka_type == 3">
              <div class="form__item">
                <label for="height">Выберите панель</label>
                <select name="panel_polka_type" id="panel_polka_type" v-model="panel_polka_type" required @change="() => {
                    if(panel_polka_type == 1) {this.panel_polka_price = 100}
                    else if(panel_polka_type == 2) {this.panel_polka_price = 200}
                  }"
                >
                  <option disabled value="0">Выберите вариант</option>
                  <option value="1">Накладная</option>
                  <option value="2">Под рамкой</option>
                </select>
              </div>
            </div>

            <div class="form__row" v-if="polka && polkaHeightCurrent >= 300">
              <div class="form__item form__checkboxes">
                <div class="checks">
                  <label for="framuga_polka_stekopaket">
                    <input
                      type="checkbox"
                      v-model="framuga_polka_stekopaket"
                      id="framuga_polka_stekopaket"
                      name="framuga_polka_stekopaket"
                      @change="resetPolkaSteklopaket"
                    />
                    <span>Стеклопакет</span>
                  </label>
                </div>
              </div>
            </div>
            
            <div class="form__highlight" v-if="polka && framuga_polka_stekopaket">
              <div class="form__row">
                <div class="form__item">
                  <select name="steklopaket_polka_type" id="steklopaket_polka_type" v-model="steklopaket_polka_type" required @change="() => {
                      decor_polka_type = 0;
                      decor_polka_led = 0;
                      decor_side_polka_type = 0;
                      if(steklopaket_polka_type == 1) {this.steklopaket_polka_price = 5000}
                      else if(steklopaket_polka_type == 2) {this.steklopaket_polka_price = 7000}
                    }"
                  >
                    <option disabled value="0">Выберите вариант</option>
                    <option value="1">Двухкамерный</option>
                    <option value="2">Трехкамерный</option>
                  </select>
                </div>
              </div>

              <div class="form__row" v-if="steklopaket_polka_type">
                <div class="form__item">
                  <label for="height">Декор</label>
                  <select name="steklopaket_polka_type" id="steklopaket_polka_type" v-model="decor_polka_type" required @change="() => {
                      decor_side_polka_type = 0;
                      if(decor_polka_type == 1) {this.decor_polka_price = 1000}
                      else if(decor_polka_type == 2) {this.decor_polka_price = 2000}
                    }"
                  >
                    <option disabled value="0">Выберите вариант</option>
                    <option value="1">Ковка</option>
                    <option value="2">Лазерная резка</option>
                  </select>
                </div>
              </div>

              <div class="form__row" v-if="decor_polka_type">
                <div class="form__item">
                  <label for="height">Размещение</label>
                  <select name="steklopaket_polka_type" id="steklopaket_polka_type" v-model="decor_side_polka_type" required @change="() => {
                      if(decor_side_polka_type == 1) {this.decor_side_polka_price = 1000}
                      else if(decor_side_polka_type == 2) {this.decor_side_polka_price = 2000}
                    }"
                  >
                    <option disabled value="0">Выберите вариант</option>
                    <option value="1">Снаружи</option>
                    <option value="2">Внутри</option>
                  </select>
                </div>
              </div>

              <div class="form__row" v-if="steklopaket_polka_type">
                <div class="form__item form__checkboxes">
                  <div class="checks">
                    <label for="decor_polka_led">
                      <input
                        type="checkbox"
                        v-model="decor_polka_led"
                        id="decor_polka_led"
                        name="decor_polka_led"
                      />
                      <span>LED подсветка</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="form__item form__description form__price" v-if="polka">
              Стоимость фрамуги: <strong> {{ parseInt(framuga ? polka_price : 0) + parseInt(polka_termo ? 5000 : 0) + parseInt(framuga_polka_madrid ? 3500 : 0) + parseInt(steklopaket_polka_price) + parseInt(panel_polka_price) }} ₽</strong>
            </div>
          </div>

          <!--/Полка-->

          <div class="form__highlight" v-if="start.id == 3 && startType || start.id == 4 && startType">
            <div class="form__row">
              <div class="form__item form__checkboxes">
                <div class="checks">
                  <label for="termo">
                    <input
                      type="checkbox"
                      v-model="termo"
                      id="termo"
                      name="termo"
                    />
                    <span>Термо</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form__item form__description form__price" v-if="termo">
              Стоимость термо: <strong> {{ parseInt(termo ? '5000' : 0) }} ₽</strong>
            </div>
          </div>

          <div class="form__highlight">
            <div class="form__row">
              <div class="form__item">
                <label for="storona">Сторонность основного полотна:</label>
                <select name="storona" id="storona" v-model="storona">
                  <option disabled value="0">Выберите вариант</option>
                  <option
                    v-for="(item, index) in storonaData"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form__row">
              <div class="form__item">
                <label for="petlya">Петли:</label>
                <select name="petlya" id="petlya" v-model="petlya">
                  <option disabled value="0">Выберите вариант</option>
                  <option v-for="(item, index) in petlyaData" :key="index" :value="item">
                    {{ index }}
                  </option>
                </select>
              </div>
              <div class="form__item" v-if="petlya">
                <label for="petlya">Количество:</label>
                <select
                  name="petlyaType"
                  id="petlyaType"
                  v-model="petlyaType"
                  class="child"
                >
                  <option disabled value="0">Выберите вариант</option>
                  <option
                    v-for="(item, index) in petlya"
                    :key="index"
                    :value="item.price"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form__row">
              <div class="form__item">
                <label for="nalichnik">Наличник:</label>
                <select name="nalichnik" id="nalichnik" v-model="nalichnik">
                  <option disabled value="0">Выберите вариант</option>
                  <option
                    v-for="(item, index) in nalichnikData"
                    :key="index"
                    :value="item.price"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <div class="form__item form__description" v-if="nalichnik">
                  Стоимость: <strong> {{ nalichnik }} ₽</strong>
                </div>
              </div>
              <div class="form__item">
                <label for="uplotnitel">{{ uplotnitelData.name }}</label>
                <select name="uplotnitel" id="uplotnitel" v-model="uplotnitel">
                  <option value="0">Базовый комплект</option>
                  <option
                    v-for="(item, index) in uplotnitelData.variant"
                    :key="index"
                    :value="item.price"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <div class="form__item form__description" v-if="uplotnitel">
                  Стоимость: <strong> {{ uplotnitel }} ₽</strong>
                </div>
              </div>
            </div>
          </div>

          <div class="form__highlight">
            <div class="form__row">
              <div class="form__item">
                <label for="otdelkan">{{ otdelkanData.name }}</label>
                <div class="form__span">
                  <select name="otdelkan" id="otdelkan" v-model="otdelkan">
                    <option disabled value="0">Выберите вариант</option>
                    <option
                      v-for="(item, index) in otdelkanData.variants"
                      :key="index"
                      :value="item"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="form__item form__description" v-if="otdelkan.price">
                  Стоимость: <strong> {{ otdelkan.price ? otdelkan.price : 0 }} ₽</strong>
                </div>
              </div>
              <div class="form__item" v-if="otdelkan.variant != null || otdelkan.id === 3">
                <label for="otdelkan">Выберите вариант</label>
                <div class="form__span">
                  <select
                    name="otdelkanVar"
                    id="otdelkanVar"
                    v-model="otdelkanVar"
                    class="child"
                  >
                    <option disabled value="0">Выберите вариант</option>
                    <option
                      v-for="(item, index) in otdelkan.variant"
                      :key="index"
                      :value="item.price"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form__row"
              v-if="
                otdelkan.id === 3 ||
                otdelkan.id === 4 ||
                otdelkan.id === 5 ||
                otdelkan.id === 6 ||
                otdelkan.id === 7
              "
            >
              <div class="form__item">
                <label for="tolshina">{{ tolshinaData.name }}</label>
                <div class="form__span">
                  <select name="tolshina" id="tolshina" v-model="tolshina">
                    <option disabled value="0">Выберите вариант</option>
                    <option
                      v-for="(item, index) in tolshinaData.types"
                      :key="index"
                      :value="item"
                    >
                      {{ item.length }} мм
                    </option>
                  </select>
                  <div class="form__item form__description" v-if="tolshina.price">
                    Стоимость: <strong> {{ tolshina.price ? tolshina.price : 0 }} ₽</strong>
                  </div>
                </div>
              </div>
            </div>
            <div class="form__row" v-if="otdelkan.id === 7">
              <div class="form__item">
                <label for="mdf">{{ mdfData.name }}</label>
                <div class="form__span">
                  <select name="mdf" id="mdf" v-model="mdf" @change="resetMdf">
                    <option disabled value="0">Выберите вариант</option>
                    <option
                      v-for="(item, index) in mdfData.variants"
                      :key="index"
                      :value="item"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form__item" v-if="mdf">
                <label for="mdf">Выберите вариант</label>
                <div class="form__span">
                  <select
                    name="mdfType"
                    id="mdfType"
                    v-model="mdfType"
                    class="child"
                  >
                    <option disabled value="0">Выберите вариант</option>
                    <option
                      v-for="(item, index) in mdf.image"
                      :key="index"
                      :value="item.price"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <div class="form__item form__description" v-if="mdfType">
                    Стоимость: <strong> {{ mdfType }} ₽</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form__highlight">
            <div class="form__row align_bottom">
              <div class="form__item">
                <label for="otdelkav">{{ otdelkavData.name }}</label>
                <div class="form__span">
                  <select name="otdelkav" id="otdelkav" v-model="otdelkav">
                    <option disabled value="0">Выберите вариант</option>
                    <option
                      v-for="(item, index) in otdelkavData.variants"
                      :key="index"
                      :value="item"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form__item" v-if="otdelkav.variant != null || otdelkav.id === 3">
                <label for="otdelkav">Варианты</label>
                <select
                  name="otdelkavVar"
                  id="otdelkavVar"
                  v-model="otdelkavVar"
                  class="child"
                >
                  <option disabled value="0">Выберите вариант</option>
                  <option
                    v-for="(item, index) in otdelkav.variant"
                    :key="index"
                    :value="item.price"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="form__item price">{{ otdelkav.price ? otdelkav.price : 0 }} ₽</div>
            </div>
            <div class="form__row align_bottom" v-if="
                  otdelkav.id === 3 ||
                  otdelkav.id === 4 ||
                  otdelkav.id === 5 ||
                  otdelkav.id === 6 ||
                  otdelkav.id === 7
                ">
              <div class="form__item">
                <label for="tolshinav">{{ tolshinaData.name }}</label>
                <div class="form__span">
                  <select name="tolshina" id="tolshinav" v-model="tolshinav">
                    <option disabled value="0">Выберите вариант</option>
                    <option
                      v-for="(item, index) in tolshinaData.types"
                      :key="index"
                      :value="item"
                    >
                      {{ item.length }} мм
                    </option>
                  </select>
                </div>
              </div>
              <div class="form__item price" v-if="tolshinav.price">{{ tolshinav.price }} ₽</div>
            </div>
            <div class="form__row" v-if="otdelkav.id === 7">
              <div class="form__item">
                <label for="mdfv">{{ mdfData.name }}</label>
                <div class="form__span">
                  <select name="mdfv" id="mdfv" v-model="mdfv">
                    <option disabled value="0">Выберите вариант</option>
                    <option
                      v-for="(item, index) in mdfData.variants"
                      :key="index"
                      :value="item"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form__item" v-if="mdfv">
                <label for="mdfv">Вариант</label>
                <div class="form__span">
                  <select
                    name="mdfTypev"
                    id="mdfTypev"
                    v-model="mdfTypev"
                    class="child"
                  >
                    <option disabled value="0">Выберите вариант</option>
                    <option
                      v-for="(item, index) in mdfv.image"
                      :key="index"
                      :value="item.price"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="form__highlight">
            <div class="form__row">
              <div class="form__item">
                <label for="colors">{{ colorsData.name }}</label>
                <div class="form__span">
                  <select name="colors" id="colors" v-model="colors">
                    <option disabled value="0">Выберите вариант</option>
                    <option
                      v-for="(item, index) in colorsData.variants"
                      :key="index"
                      :value="item"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form__row" v-if="colors">
              <div class="form__item">
                <label for="colors">Коллекция</label>
                <select
                  name="colorCollection"
                  id="colorCollection"
                  v-model="colorCollection"
                  class="child"
                >
                  <option disabled value="0">Выберите вариант</option>
                  <option
                    v-for="(item, index) in colors.collections"
                    :key="index"
                    :value="item"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form__row" v-if="colorCollection">
              <div class="form__item">
                <label for="colors">Вариант</label>
                <div class="form__span">
                  <select
                    name="colorMark"
                    id="colorMark"
                    v-model="colorMark"
                    class="child"
                  >
                    <option disabled value="0">Выберите вариант</option>
                    <option
                      v-for="(item, index) in colorCollection.mark"
                      :key="index"
                      :value="item.price"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="form__highlight">
            <div class="form__row">
              <div class="form__item">
                <label for="zamok">{{ zamokData.name }}</label>
                <select name="zamok" id="zamok" v-model="zamok">
                  <option disabled value="0">Выберите вариант</option>
                  <option
                    v-for="(item, index) in zamokData.variants"
                    :key="index"
                    :value="item"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form__row" v-if="zamok">
              <div class="form__item">
                <label for="zamok">Комплект</label>
                <select
                  name="zamokType"
                  id="zamokType"
                  v-model="zamokType"
                  class="child"
                >
                  <option disabled value="0">Выберите вариант</option>
                  <option v-for="(item, index) in zamok.type" :key="index" :value="item">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form__row" v-if="zamokType">
              <div class="form__item">
                <label for="zamok">Вариант</label>
                <select
                  name="zamokVar"
                  id="zamokVar"
                  v-model="zamokVar"
                  class="child"
                >
                  <option disabled value="0">Выберите вариант</option>
                  <option
                    v-for="(item, index) in zamokType.variant"
                    :key="index"
                    :value="item.price"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="form__highlight">
            <div class="form__row">
              <div class="form__item">
                <label for="furnitura">{{ furnituraData.name }}</label>
                <div class="form__span">
                  <select
                    name="furnitura"
                    id="furnitura"
                    v-model="furnitura"
                    @change="resetComplectfurnitura"
                  >
                    <option disabled value="0">Выберите вариант</option>
                    <option
                      v-for="(item, index) in furnituraData.variants"
                      :key="index"
                      :value="item"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form__row" v-if="furnitura.id === 1">
              <div class="form__item">
                <label for="complectfurnitura">{{ complectfurnituraData.name }}</label>
                <select
                  name="complectfurnitura"
                  id="complectfurnitura"
                  v-model="complectfurnitura"
                >
                  <option disabled value="0">Выберите вариант</option>
                  <option
                    v-for="(item, index) in complectfurnituraData.variants"
                    :key="index"
                    :value="item.price"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form__row" v-if="furnitura.id === 2">
              <div class="form__item">
                <label for="ruchka">{{ ruchkaData.name }}</label>
                <select name="ruchka" id="ruchka" v-model="ruchka">
                  <option disabled value="0">Выберите вариант</option>
                  <option
                    v-for="(item, index) in ruchkaData.variants"
                    :key="index"
                    :value="item.price"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form__row" v-if="furnitura.id === 2">
              <div class="form__item">
                <label for="nakladaosnov">{{ nakladaosnovData.name }}</label>
                <select name="nakladaosnov" id="nakladaosnov" v-model="nakladaosnov">
                  <option disabled value="0">Выберите вариант</option>
                  <option
                    v-for="(item, index) in nakladaosnovData.variant"
                    :key="index"
                    :value="item.price"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form__row" v-if="furnitura.id === 2">
              <div class="form__item">
                <label for="nakladadop">{{ nakladadopData.name }}</label>
                <select name="nakladadop" id="nakladadop" v-model="nakladadop">
                  <option disabled value="0">Выберите вариант</option>
                  <option
                    v-for="(item, index) in nakladadopData.variant"
                    :key="index"
                    :value="item.price"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="form__highlight">
            <div class="form__row">
              <div class="form__item">
                <label for="glazok">{{ glazokData.name }}</label>
                <div class="form__span">
                  <select name="glazok" id="glazok" v-model="glazok">
                    <option value="0">Нет</option>
                    <option
                      v-for="(item, index) in glazokData.variant"
                      :key="index"
                      :value="item.price"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form__row">
              <div class="form__item">
                <label for="nzadvizhka">{{ nzadvizhkaData.name }}</label>
                <div class="form_-span">
                  <select name="nzadvizhka" id="nzadvizhka" v-model="nzadvizhka">
                    <option
                      v-for="(item, index) in nzadvizhkaData.variant"
                      :key="index"
                      :value="item.price"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form__row">
              <div class="form__item form__checkboxes">
                <label for="dop">{{ dopData.name }}</label>
                <div class="checks checks_list">
                  <label
                    v-for="(item, index) in dopData.variants"
                    :key="index"
                    :for="'item_' + index"
                  >
                    <input
                      type="checkbox"
                      v-model="dop"
                      :id="'item_' + index"
                      :value="item.price"
                      name="dop"
                    />
                    <span>{{ item.name }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- <label for="width">
            Ширина
            <input type="number" v-model="width" :input="area = width * lenght" id="width">
          </label>
          <label for="lenght">
            Длина
            <input type="number" v-model="lenght" :input="area = width * lenght" id="lenght">
          </label> -->
        </form>

        <div class="total_wrapper">
          <span><strong>Цена:</strong> {{ getTotal }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";

import start from "@/data/0start.json";
import storona from "@/data/2storona.json";
import metal from "@/data/3metal.json";
import petlya from "@/data/4petlya.json";
import nalichnik from "@/data/5nalichnik.json";
import uplotnitel from "@/data/6uplotnitel.json";
import otdelkan from "@/data/7otdelkan.json";
import tolshina from "@/data/8tolshina.json";
import mdf from "@/data/9mdf.json";
import otdelkav from "@/data/10otdelkav.json";
import colors from "@/data/11colors.json";
import zamok from "@/data/12zamok.json";
import furnitura from "@/data/13furnitura.json";
import complectfurnitura from "@/data/14complectfurnitura.json";
import ruchka from "@/data/15ruchka.json";
import nakladaosnov from "@/data/16nakladaosnov.json";
import nakladadop from "@/data/17nakladadop.json";
import glazok from "@/data/18glazok.json";
import nzadvizhka from "@/data/19nzadvizhka.json";
import dop from "@/data/20dop.json";

export default {
  name: "Home",
  components: {
    Logo,
  },
  data() {
    return {
      start: 0,
      startType: 0,
      storona: 0,
      metal: 0,
      petlya: 0,
      petlyaType: 0,
      nalichnik: 0,
      uplotnitel: 0,
      otdelkan: 0,
      otdelkanVar: 0,
      tolshina: 0,
      mdf: 0,
      mdfType: 0,
      otdelkav: 0,
      otdelkavVar: 0,
      tolshinav: 0,
      mdfv: 0,
      mdfTypev: 0,
      colors: 0,
      colorCollection: 0,
      colorMark: 0,
      zamok: 0,
      zamokType: 0,
      zamokVar: 0,
      furnitura: 0,
      complectfurnitura: 0,
      ruchka: 0,
      nakladaosnov: 0,
      nakladadop: 0,
      glazok: 0,
      nzadvizhka: 0,

      nestHeightMin: 2101,
      nestHeightMax: 2400,
      nestWidthMin: 1001,
      nestWidthMax: 1050,
      nestHeightCurrent: 2101,
      nestWidthCurrent: 1001,
      nestValue: 1.2,

      dvHeightMin: 500,
      dvHeightMax: 2100,
      dvWidthMin: 500,
      dvWidthMid: 1500,
      dvWidthMax: 3000,
      dvHeightCurrent: 500,
      dvWidthCurrent: 500,
      dvValue: 2,
      dvValueMax: 2.5,

      framuga: false,
      polka: false,
      termo: false,

      framugaHeightMin: 1,
      framugaHeightMax: 1000,
      framugaHeightCurrent: 1,
      framuga_termo: false,
      framuga_price: 2000,
      framuga_madrid: false,
      framuga_vatikan: false,
      framuga_stekopaket: false,
      framuga_panel: false,
      steklopaket_type: 0,
      steklopaket_price: 0,
      panel_type: 0,
      panel_price: 0,

      felenka_type: 0,
      felenka_price: 0,
      decor_type: 0,
      decor_price: 0,
      decor_led: 0,
      decor_side_type: 0,
      decor_side_price: 0,

      polkaHeightMin: 1,
      polkaHeightMax: 600,
      polkaHeightCurrent: 1,
      polka_termo: false,
      polka_price: 2000,
      polka_madrid: false,
      polka_vatikan: false,
      framuga_polka_stekopaket: false,
      polka_panel: false,
      steklopaket_polka_type: 0,
      steklopaket_polka_price: 0,
      panel_polka_type: 0,
      panel_polka_price: 0,

      felenka_polka_type: 0,
      felenka_polka_price: 0,
      decor_polka_type: 0,
      decor_polka_price: 0,
      decor_polka_led: 0,
      decor_side_polka_type: 0,
      decor_side_polka_price: 0,

      dop: [],
      startData: start,
      storonaData: storona,
      metalData: metal,
      petlyaData: petlya,
      nalichnikData: nalichnik,
      uplotnitelData: uplotnitel,
      otdelkanData: otdelkan,
      tolshinaData: tolshina,
      mdfData: mdf,
      otdelkavData: otdelkav,
      colorsData: colors,
      zamokData: zamok,
      furnituraData: furnitura,
      complectfurnituraData: complectfurnitura,
      ruchkaData: ruchka,
      nakladaosnovData: nakladaosnov,
      nakladadopData: nakladadop,
      glazokData: glazok,
      nzadvizhkaData: nzadvizhka,
      dopData: dop,
    };
  },
  methods: {
    resetComplectfurnitura() {
      this.startType = 0
      this.complectfurnitura = 0
      this.ruchka = 0
      this.nakladaosnov = 0
      this.nakladadop = 0,
      this.metal = 0
      this.framuga = 0
      this.framugaHeightMin = 1
      this.framugaHeightMax = 1000
      this.framugaHeightCurrent = 1
      this.framuga_termo = false
      this.framuga_price = 2000
      this.framuga_madrid = false
      this.framuga_vatikan = false
      this.framuga_stekopaket = false
      this.framuga_panel = false
      this.steklopaket_price = 0
      this.steklopaket_type = 0
      this.panel_type = 0
      this.panel_price = 0
      this.termo = false
      this.felenka_type = 0
      this.felenka_price = 0
      this.decor_type = 0
      this.decor_price = 0
      this.decor_led = 0
      this.decor_side_type = 0
      this.decor_side_price = 0
      this.polka = 0
      this.polkaHeightMin = 1
      this.polkaHeightMax = 600
      this.polkaHeightCurrent = 1
      this.polka_termo = false
      this.polka_price = 2000
      this.polka_madrid = false
      this.polka_vatikan = false
      this.framuga_polka_stekopaket = false
      this.polka_panel = false
      this.steklopaket_polka_price = 0
      this.steklopaket_polka_type = 0
      this.panel_polka_type = 0
      this.panel_polka_price = 0
      this.polka_termo = false
      this.felenka_polka_type = 0
      this.felenka_polka_price = 0
      this.decor_polka_type = 0
      this.decor_polka_price = 0
      this.decor_polka_led = 0
      this.decor_side_polka_type = 0
      this.decor_side_polka_price = 0
    },
    resetMetall() {
      this.metal = 0
      this.framuga = 0
      this.polka = 0
    },
    resetFramugaType() {
      this.framugaHeightMin = 1
      this.framugaHeightMax = 1000
      this.framugaHeightCurrent = 1
      this.framuga_termo = false
      this.framuga_price = 2000
      this.framuga_madrid = false
      this.framuga_vatikan = false
      this.framuga_stekopaket = false
      this.framuga_panel = false
      this.steklopaket_price = 0
      this.panel_price = 0
      this.felenka_type = 0
      this.felenka_price = 0
      this.decor_type = 0
      this.decor_price = 0
      this.decor_led = 0
      this.decor_side_type = 0
      this.decor_side_price = 0
      this.polkaHeightMin = 1
      this.polkaHeightMax = 600
      this.polkaHeightCurrent = 1
      this.polka_termo = false
      this.polka_price = 2000
      this.polka_madrid = false
      this.polka_vatikan = false
      this.framuga_polka_stekopaket = false
      this.polka_panel = false
      this.steklopaket_polka_price = 0
      this.steklopaket_polka_type = 0
      this.panel_polka_type = 0
      this.panel_polka_price = 0
      this.polka_termo = false
      this.felenka_polka_type = 0
      this.felenka_polka_price = 0
      this.decor_polka_type = 0
      this.decor_polka_price = 0
      this.decor_polka_led = 0
      this.decor_side_polka_type = 0
      this.decor_side_polka_price = 0
    },
    resetSteklopaket() {
      this.steklopaket_price = 0
      this.steklopaket_type = 0
    },
    resetPanel() {
      this.panel_price = 0
      this.panel_type = 0
    },
    resetMdf() {
      this.mdfType = 0
    }
  },
  computed: {
    getTotal() {
      return (
        ((parseInt(this.startType.price) +
        parseInt(this.startType.price) * (parseInt(this.startType.extra) / 100) +
        parseInt(this.metal)) *
        parseFloat((this.start.id == 3 && this.startType) ? this.nestValue : 1) *
        parseFloat(this.start.id == 4 && this.startType ? (this.dvWidthCurrent > this.dvWidthMid ? this.dvValueMax : this.dvValue) : 1)) +
        parseInt(this.framuga ? this.framuga_price : 0) +
        parseInt(this.framuga_termo ? 5000 : 0) +
        parseInt(this.felenka_price) +
        parseInt(this.steklopaket_price) +
        parseInt(this.panel_price) +
        parseInt(this.decor_price) +
        parseInt(this.decor_led ? 500 : 0) +
        parseInt(this.decor_side_price) +
        parseInt(this.polka ? this.polka_price : 0) +
        parseInt(this.polka_termo ? 5000 : 0) +
        parseInt(this.felenka_polka_price) +
        parseInt(this.steklopaket_polka_price) +
        parseInt(this.panel_polka_price) +
        parseInt(this.decor_polka_price) +
        parseInt(this.decor_polka_led ? 500 : 0) +
        parseInt(this.decor_side_polka_price) +
        parseInt(this.petlyaType) +
        parseInt(this.nalichnik) +
        parseInt(this.uplotnitel) +
        parseInt(this.otdelkan.price ? this.otdelkan.price : 0) +
        parseInt(this.tolshina.price ? this.tolshina.price : 0) +
        parseInt(this.mdfType) +
        parseInt(this.otdelkav.price ? this.otdelkav.price : 0) +
        parseInt(this.tolshinav.price ? this.tolshinav.price : 0) +
        parseInt(this.mdfTypev) +
        parseInt(this.colorMark ? this.colorMark : 0) +
        parseInt(this.zamokVar) +
        parseInt(this.complectfurnitura ? this.complectfurnitura : 0) +
        parseInt(this.ruchka) +
        parseInt(this.nakladaosnov) +
        parseInt(this.nakladadop) +
        parseInt(this.glazok) +
        parseInt(this.nzadvizhka ? this.nzadvizhka : 0) +
        parseInt(this.termo ? '5000' : 0) +
        this.dop.reduce((acc, item) => acc + item, 0) || 0
      );
    },
  },
  created() {
    this.start = this.startData.variants[0];
    this.startType = this.startData.variants[0].variant[0].price;
  },
};
</script>

<style lang="stylus">

.container
  max-width 600px
  padding 0 30px 130px
  margin 0 auto

h1
  text-align center
  margin-bottom 30px

label
  display block
  margin-bottom 5px
  font-weight bold
  font-size 0.9em
  width 100%

input:not([type=checkbox])
select
  border 1px solid #d5d5d5
  padding 5px
  height 40px
  font-size 1em
  width 100%
  transition all 0.3s
  flex 1

  &:focus
    box-shadow alpha(#000, 15%) 0 0 0 3px

p
  margin-bottom 10px

  &:last-child
    margin-bottom 0

option.disabled
  color #e5e5e5

.form__row
  display flex
  align-items flex-start
  gap 20px
  flex-wrap wrap
  margin-bottom 20px

  &.align_bottom
    align-items flex-end

  &:last-child
    margin-bottom 0

.form__item
  flex 1

  .form__description
    margin-top 5px

.form__highlight
  padding 20px
  border 1px solid #d5d5d5
  background #f5f5f5
  margin-bottom 20px

.form__description:not(.form__price)
  width 100%
  font-size 0.9em
  line-height 1.3em
  flex initial
  opacity 0.5

.form__price
  display flex
  align-items center
  gap 5px

.price__description
  opacity 0.5
  font-size 0.8em

h3
h4
  width 100%

.price
  flex initial
  height 40px
  display inline-flex
  align-items center
  gap 5px

.form__checkboxes
  > label
    margin-bottom 10px

.checks
  display grid
  gap 5px

  label
    font-weight normal
    margin 0

  input
    margin-right 5px

.total_wrapper
  position fixed
  left 0
  bottom 0
  height 100px
  width 100%
  padding 15px
  background #f5f5f5
  text-align center
  margin-top 30px
  font-size 2em
  display flex
  align-items center
  justify-content center
  
</style>
